import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const AgilisArea = styled.section`
  background-color: #f7fafa;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    .image-header {
      width: 100% !important;
    }
  }
  .container {
    border-top: 1px solid #e1e0eb;
    text-align: center;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.75;
    text-align: center;
    letter-spacing: -0.015em;
    color: #696871;
    background-color: #f7fafa;
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    top: -15px;
  }
  h1, h2, h3, h4 {
    text-align: center;
    color: rgb(15, 33, 55);
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: -0.025em;
  }
  h1, h2 {
    color: #e74e00;
    border-bottom: 1px solid #F7A61B;
    text-transform: uppercase;
  }
  .image-header {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .row {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .advantages {
    text-align: left;
    margin-left: 20px;
  }
  .advantages > li {
    font-size: 18px;
    line-height: 2.5rem;
  }
  .figure {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @media (min-width: 1200px) {
      width: 930px;
    }
    img {
      border-radius: 4px;
    }
    .fig__caption {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      .reusecore__button {
        .btn-icon {
          background-color: ${themeGet('colors.white', '#ffffff')};
          line-height: 0.4;
        }
      }
    }
  }
`;

export default AgilisArea;
