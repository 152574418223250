import AgilisArea from './agilisPage.style';
import React from 'react';
import Image from 'common/components/Image';
import Box from 'common/components/Box';
import Container from 'common/components/UI/Container';
import AgilisImage from 'common/assets/image/agency/agilis.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import GatsbyImage from 'common/components/GatsbyImage';
import { openModal, closeModal } from '@redq/reuse-modal';
import Button from 'common/components/Button';
import { graphql, useStaticQuery } from "gatsby";

const IntroVideo = () => (
    <iframe
        title="Lanzamiento Agilis"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/iQwqelBKhZs?controls=0&showinfo=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
);

const CloseModalButton = () => (
    <Button
        className="modalCloseBtn"
        variant="fab"
        onClick={() => closeModal()}
        icon={<i className="flaticon-plus-symbol" />}
    />
);

const AgilisPage = ({
    row,
    buttonStyle,

 }) => {

    // Video modal handler
    const handleVideoModal = () => {
        openModal({
            config: {
                className: 'video-modal',
                disableDragging: true,
                default: {
                    width: '100%',
                    height: '100%',
                    x: 0,
                    y: 0,
                },
            },
            component: IntroVideo,
            componentProps: {},
            closeComponent: CloseModalButton,
            closeOnClickOutside: false,
        });
    };

    const Data = useStaticQuery(graphql`
        query {
          featureImage: file(
            relativePath: { eq: "image/agency/preview-image.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
    `);

    return (
        <AgilisArea id="AgilisSection">
            <Container>
                <Box className="row" {...row}>
                    <h1>AGILIS: Eficiencia y Calidad Premium en Repintado Rápido</h1>
                    <h3>Explora Agilis, la solución premium de BASF en repintado de automoción, destacada por su innovador sistema de pintura base acuosa AGILIS®. Con un enfoque en la sostenibilidad ambiental y la eficiencia, Agilis establece nuevos estándares en la industria, liderando con tecnología avanzada y compromiso ecológico.</h3>
                    <Image className="patternImage image-header" src={AgilisImage} alt="Marca Agilis" />
                </Box>
                {/* Sección de Ventajas */}
                <Box className="row" {...row}>
                    <h2>Ventajas de Agilis</h2>
                    <ul className="advantages">
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Eficiencia en Tiempo:</strong> Agilis se destaca por ser un 31% más rápido que una línea convencional de base acuosa, lo que permite completar trabajos de repintado en menor tiempo y aumentar la productividad.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Ahorro en Materiales y Tiempo de Aplicación:</strong> Con Agilis, experimentarás una reducción del 35% en el consumo de materiales y un 20% en los tiempos de aplicación, lo que se traduce en un ahorro significativo tanto en recursos como en costes operativos.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Tecnología de Pigmentos Avanzada:</strong> Capacidad de cubrimiento excepcional y facilidad de uso para acabados impecables.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Bajo Contenido de VOC:</strong> Línea de base acuosa con contenido VOC menor a 250 g/L, un 40% menos que el límite de la UE.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Eco-efectividad:</strong> La mayor relación de eco-efectividad en la industria, promoviendo una huella ecológica más pequeña.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Compromiso con la Calidad:</strong> Enfoque en recubrimientos acuosos y de alto contenido sólido con bajo contenido de solventes.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Innovación y Liderazgo:</strong> Líder en innovaciones de producto, color y proceso, con un enfoque en sostenibilidad.</li>
                    </ul>
                </Box>
                <Box className="row mt-20" {...row}>
                    <h2>Descubre Agilis, tu elección sostenible para el repintado de automoción</h2>
                </Box>
                <Box className="row figure" {...row}>
                    <GatsbyImage
                        src={
                            (Data.featureImage !== null) | undefined
                                ? Data.featureImage.childImageSharp.gatsbyImageData
                                : {}
                        }
                        alt="Video Preview Image"
                    />
                    <Box className="fig__caption">
                        <Button
                            {...buttonStyle}
                            icon={<i className="flaticon-youtube" />}
                            onClick={handleVideoModal}
                            aria-label="play"
                        />
                    </Box>
                </Box>
            </Container>
        </AgilisArea>
  );
};

// VideoSection default style
AgilisPage.defaultProps = {
    // button default design
    buttonStyle: {
        variant: 'textButton',
        p: 0,
        color: '#ec4444',
        fontSize: '71px',
    },
    imageWrapper: {
        display: ' block',
    },
};

export default AgilisPage;
